<template>
    <div class="privacy">
      <div class="title">
        <h1>权限说明</h1>
      </div>
      <p class="bold">用户在使用{{ company }}的所提供的移动游戏产品及服务过程中：</p>
      <p>
        我们通常情况下会在以下场景中收集和使用您的个人信息。
      </p>
      <p>
        当您注册账号时，我们会收集、使用您的账号信息（包括，账号ID、用户名、昵称、密码）、姓名、身份证号码，收集这些信息是为了帮助您完成账号注册和使用我们提供的产品和服务，并确保您的账户安全。收集、使用姓名、身份证号码是为了满足《国家新闻出版署关于防止未成年人沉迷网络游戏的通知》及《国家新闻出版署关于进一步严格管理切实防止未成年人沉迷网络游戏的通知》有关落实网络游戏用户账号实名注册的要求。若您不提供这类信息，您可能无法正常注册账号。此外，为便于您找回您的账号，在您同意的前提下，我们会收集您的电子邮箱、手机号码；若您不提供该信息，您将无法使用电子邮箱、手机号码账号找回功能，但不影响您正常使用其他功能。
      </p>
      <p>
        当您使用提供的产品和服务时，我们会收集、使用您的手机厂商标识、手机型号、手机系统版本、网络类型/运营商、手机网卡mac地址、手机内存及硬盘信息（总大小、剩余量）、CPU信息（包括，名称、架构、最大频率、核数）、手机屏幕分辨率、模拟器判断、是否root、粘贴板、vaid、oaid、aaid、GAid、Android ID、IMSI、MEID（标识用户）、设备序列号（标识用户）、SD卡读取（保存账号）、SIM卡信息（一键登录）、SD卡_创建目录（生成并存储玩家账号、密码）、获取SIM卡ICCID:（标识用户信息）、获取存储信息:（用户登入游戏） GdtAd、Glide、ExoPlayer、百度移动广告SDK、百度定位、百度网盟广告、今日头条穿山甲联盟SDK、Gromore SDK、抖音直播SDK、极光SDK、sigmobSDK、tobidSDK、热云SDK、数数SDK移动授权登录（用于推送广告以及播放广告视频内容） ,运行中的进程以及方向传感器。收集这些信息是为了保障您正常使用我们的服务，维护我们服务的正常运行以及保障您的账号安全，实现防沉迷功能。若您不提供这类信息，您可能无法正常使用我们的产品和服务。为了为您推荐您可能感兴趣的内容/商业广告等信息、丰富信息推荐维度，我们会向您请求开启 地理位置权限。您可以拒绝开启，拒绝开启后，您看到的广告数量将保持不变，但广告的相关度会降低。精确地理位置信息属于个人敏感信息，拒绝提供该信息不影响您正常使用本游戏的其他功能。
      </p>
      <p>
        当您使用新新提供的产品和服务时，我们还会收集、使用您的日志信息，包括IP地址、登陆时间、搜索查询内容、使用语言、服务故障信息、引荐网址、浏览器标识。收集这些信息是为了保障您正常使用我们的服务，维护我们服务的正常运行以及满足监管要求。若您不提供这类信息，您可能无法正常使用我们的产品和服务。为了为您推荐您可能感兴趣的内容/商业广告等信息、丰富信息推荐维度，我们会向您请求开启 地理位置权限。您可以拒绝开启，拒绝开启后，您看到的广告数量将保持不变，但广告的相关度会降低。精确地理位置信息属于个人敏感信息，拒绝提供该信息不影响您正常使用本游戏的其他功能。
      </p>
       <p>1.保障本游戏软件及相关服务的安全运行、运营的质量及效率</p>
      <p>当您使用本游戏软件及相关服务时，为了保障软件与服务的安全运行、运营的质量及效率，我们会收集你的硬件型号、操作系统版本号、设备标识符（Android如IMEI、AndroidID、OAID、IMSI、ICCID、GAID、MEID，iOS如IDFV、IDFA；不同的标识符在有效期、是否可由用户重置以及获取方式方面会有所不同）、网络设备硬件地址（MAC地址）、硬件序列号、IP 地址、WLAN接入点（如SSID，BSSID）、蓝牙（Bluetooth）、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志、传感器信息等数据。 请您了解，这些信息是我们提供服务和保障产品正常运行所必须收集的基本信息。</p>
      <p>2.涉及个人信息:设备标识符(Android如Android ID、IMEI、MEID、IMSI、OAID、GAID、Serial,iOS如IDFV、IDFA)、MAC地址、IP地址、位置信息(如GPS、WLAN接入点、蓝牙和基站)、设备传感器、当前应用信息(应用名、 用版本号)</p>
      <p>3.经您同意，我们只会与第三方共享实现目的所必要的信息。如果第三方因业务需要，确需超出前述授权范围使用个人信息的，该第三方将需再次征求您的同意。</p>
      <p>对我们与之共享您个人信息的第三方，我们将审慎评估该第三方数据使用共享信息的目的，对其安全保障能力进行综合评估，并要求其遵循合作法律协议。我们会对合作方获取信息的软件工具开发包（SDK）、应用程序接口（API）进行严格的安全监测，以保护数据安全。您可以点击 <b>《第三方SDK目录》</b>，了解我们主要接入的第三方SDK基本情况，各单项服务接入第三方SDK的情况可能有不同，如需了解具体单项服务接入第三方SDK的详细情况，请您前往对应的服务页面并查阅我们各单项服务的具体条款和相关页面。您也可以选择通过本政策“如何联系我们”章节所列的反馈渠道联系我们，我们会尽快为您作出解答。</p>
       
    </div>
  </template>
  
  <script>
  export default {
    name: 'privacy',
    data() {
      return {
        Date1: '',
        Date2: '',
        gameName: '',
        company: '',
        Date3: '',
        main: '',
        contact: '',
      }
    },
    mounted() {
      this.Date1 = this.$utils.getUrlKey('Date1')
      this.Date2 = this.$utils.getUrlKey('Date2')
      this.gameName = this.$utils.getUrlKey('gameName')
      this.company = this.$utils.getUrlKey('company')
      this.Date3 = this.$utils.getUrlKey('Date3')
      this.main = this.$utils.getUrlKey('main')
      if (this.$utils.getUrlKey('contact') != "" && this.$utils.getUrlKey('contact') != null) {
        this.contact = "联系方式:" + this.$utils.getUrlKey('contact')
      } else {
        this.contact = ""
      }
    },
    methods: {
      agreement() {
        let routerData = this.$router.resolve({
          path: '/children',
          query: {
            company: this.company,
            Date3: this.Date3,
            main: this.main
          }
        })
        window.open(routerData.href, '_blank')
      },
      sdk() {
        let router = this.$router.resolve({
          path: '/SDKintro'
        })
        window.open(router.href, '_blank')
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .privacy {
    .title {
      text-align: center;
    }
    .bold {
      font-weight: bold;
    }
    .agreement {
      color: royalblue;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  </style>
  